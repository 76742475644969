<template>
  <div>
    <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
      <v-col cols="12">
        <h4 class="titlePages text-left font-weight-medium mb-0">Configuração de bancos</h4>
        <p
          style="max-width: 60%"
          class="text-body-2 font-weight-light text-left mt-2"
        >
          Acesse seus produtos de pagamento para controlar seu faturameto,
          gerenciar e gerar boletos, configurar operadoras de cartões de crédito
          e visualizar suas transações.
        </p>
      </v-col>
    </v-row>
    <CardBank />
  </div>
</template>

<script>
import CardBank from "../CardBank.vue";
export default {
  beforeMount() {},
  methods: {},
  data() {
    return {};
  },
  components: { CardBank },
};
</script>

<style lang="scss" scoped></style>